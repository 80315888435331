<template>
  <section class="sidebar--content">
    <div v-if="!sidebarContent">
      <!--      <div class="sidebar&#45;&#45;item" @click="changeContent('rent')">-->
      <!--        <div>{{ $t('general.rent') }}</div>-->
      <!--        <img src="@/assets/img/icons/arrow-right.svg" alt="Arrow Right" />-->
      <!--      </div>-->
      <div class="sidebar--item" @click="changeContent('buy')">
        <div>{{ $t('general.buy') }}</div>
        <img src="@/assets/img/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
      <a href="https://komunitas.belirumah.co" target="_blank" class="sidebar--item">
        <div>{{ $t('footer.company.forum') }}</div>
      </a>
      <a href="https://blog.belirumah.co" target="_blank" class="sidebar--item">
        <div>{{ $t('footer.company.blog') }}</div>
      </a>
      <div class="sidebar--item" @click="goToPage('/kalkulator')">
        <div>{{ $t('general.mortgage') }}</div>
      </div>
      <a
        :href="`${agentWebsiteUrl}/agent-offerings`"
        class="sidebar--item"
        style="text-decoration: none"
      >
        {{ $t('general.placeAds') }}
      </a>
      <!--      <div class="sidebar&#45;&#45;item" @click="goToPage('/mitra-agen')">-->
      <!--        <div>{{ $t('general.agentPartner') }}</div>-->
      <!--      </div>-->
      <!--      <div class="sidebar&#45;&#45;item" @click="goToPage('/mitra-proyek')">-->
      <!--        <div>{{ $t('general.developerPartner') }}</div>-->
      <!--      </div>-->
      <!--      <div class="sidebar&#45;&#45;item" @click="goToPage('/project/add')">-->
      <!--        {{ $t('general.addProperty') }}-->
      <!--      </div>-->
      <!--      <div class="sidebar&#45;&#45;item" @click="goToPage('/help')">-->
      <!--        {{ $t('footer.company.helpCenter') }}-->
      <!--      </div>-->
      <!--      <div class="sidebar&#45;&#45;item" @click="changeContent('search')">-->
      <!--        <div>Temukan</div>-->
      <!--        <img src="@/assets/img/icons/arrow-right.svg" alt="Arrow Right" />-->
      <!--      </div>-->
      <div v-if="loggedIn" class="sidebar--item" @click="logout">
        <div>{{ $t('home.sidebarMenu.logout') }}</div>
      </div>
    </div>
    <div v-if="sidebarContent === 'buy'">
      <!--      <div class="sidebar&#45;&#45;item-title">-->
      <!--        {{ $t('general.buyHouse') }}-->
      <!--      </div>-->
      <div
        class="sidebar--item"
        v-for="(item, index) in propertyTypes"
        :key="`residential-${index}`"
        @click="goToSearch(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div v-else-if="sidebarContent === 'search'"></div>
  </section>
</template>

<script>
import auth from '@/mixins/auth.js';
import { mapState } from 'vuex';

export default {
  name: 'sidebar-content',
  mixins: [auth],
  data: () => ({
    propertyTypesResidential: [],
    propertyTypesCommercial: [],
  }),
  computed: {
    ...mapState({
      loggedIn: (state) => state.global.loggedIn,
      sidebarContent: (state) => state.global.sidebarContent,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    agentWebsiteUrl() {
      return process.env.VUE_APP_AGENT_URL;
    },
  },
  methods: {
    changeContent(name) {
      this.$store.commit('global/SET_SIDEBAR_CONTENT', name);
      this.getPropertyTypes();
    },
    async getPropertyTypes() {
      await this.$store.dispatch('v2/masters/getPropertyTypes');
    },
    async goToSearch(propertyTypeId) {
      try {
        this.$store.commit('global/SET_MAIN_SIDEBAR', false);
        this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
        let propertyType = await this.propertyTypes.find(function (item) {
          return item ? item.id === parseInt(propertyTypeId) : false;
        });
        if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
        this.$store.commit(
          'v2/search/SET_SELECTED_PROPERTY_TYPE',
          propertyType ? propertyType : null,
        );
        await this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
          query: {},
        });
      } catch (e) {
        console.log('ERROR GOING TO SEARCH PAGE: ', e);
      }
    },
    goToPage(route) {
      this.$store.commit('global/SET_MAIN_SIDEBAR', false);
      this.$store.commit('global/SET_SIDEBAR_CONTENT', null);
      this.$router.push(route);
    },
  },
};
</script>
